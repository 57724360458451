import { useRef } from 'react'
import { SvgIconType, ButtonSvgIcon } from '@integration-app/react'
import Editor, { EditorProps, Monaco } from '@monaco-editor/react'

export default function TextEditor({
  value,
  onChange,
  language,
  readOnly,
  scrollable = true,
  loading = false,
  ...props
}: {
  value: any
  scrollable?: boolean
  onChange: (value: any) => void
  language?: string
  readOnly?: boolean
  loading?: boolean
} & EditorProps) {
  const monacoRef = useRef(null)
  const theme = 'integrationAppTheme'
  const containerRef = useRef<HTMLDivElement>(null)

  function handleEditorWillMount(monaco: Monaco) {
    props.beforeMount?.(monaco)
    monaco.editor.defineTheme(theme, {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: {},
    })
  }

  function handleEditorDidMount(editor) {
    editor.getModel().updateOptions({ tabSize: 2 })
    monacoRef.current = editor

    if (scrollable) {
      return
    }

    const updateHeight = () => {
      const contentHeight = editor.getContentHeight()
      const parentWidth = containerRef?.current?.offsetWidth
      if (containerRef.current) {
        containerRef.current.style.height = `${contentHeight}px`
        try {
          editor.layout({ width: parentWidth, height: contentHeight })
        } catch {}
      }
    }

    editor.onDidContentSizeChange(updateHeight)
    updateHeight()
  }

  return (
    <div
      ref={containerRef}
      className={'w-full h-full flex flex-col items-center justify-center'}
      style={{ minHeight: 150 }}
    >
      {loading && (
        <span className={'text-neutral05 text-sm flex items-center'}>
          <ButtonSvgIcon size={'small'} type={SvgIconType.Loader} />
          Loading
        </span>
      )}
      {!loading && (
        <Editor
          {...props}
          value={value}
          defaultValue={value}
          onChange={(value) => onChange(value)}
          width='100%'
          height='100%'
          theme={theme}
          language={language}
          beforeMount={handleEditorWillMount}
          onMount={handleEditorDidMount}
          options={{
            minimap: {
              enabled: false,
            },
            glyphMargin: false,
            lineDecorationsWidth: 0,
            // folding: false,
            // lineNumbers: 'off',
            lineNumbersMinChars: 1,
            readOnly,
            ...(props.options ?? {}),
            ...(!scrollable && {
              scrollbar: {
                useShadows: false,
                vertical: 'hidden',
                alwaysConsumeMouseWheel: false,
              },
              scrollBeyondLastLine: false,
              wrappingStrategy: 'advanced',
              overviewRulerLanes: 0,
              wordWrap: 'off',
            }),
          }}
        />
      )}
    </div>
  )
}
