import { useState } from 'react'
import { SimpleInput, Cell, Row } from '@integration-app/react'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import { TbAlertTriangle } from 'react-icons/tb'

import { Alert } from 'components/Alert'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import { useSyncKeyWithName } from 'components/IntegrationElements/hooks/useSyncKeyWithName'
import { Popup } from 'components/Popup'
import { VStack } from 'styled-system/jsx'
import { Button } from 'ui-kit/button'

export default function CreateElementPopup<
  CreateElementRequest extends {
    integrationId?: string
    key: string
    name: string
  },
>({
  elementName,
  onCreate,
  onClose,
  defaultCreateData,
  isCreateDataValid = (data: Partial<CreateElementRequest>) =>
    !!data.name && !!data.key,
  AdditionalInputs,
}: {
  elementName: string
  onCreate(data: CreateElementRequest): Promise<void>
  onClose: () => void
  defaultCreateData: CreateElementRequest
  isCreateDataValid?(data: Partial<CreateElementRequest>): boolean

  AdditionalInputs?: JSX.Element
}) {
  const formId = 'create-element-form'
  const [item, setItem] = useState<CreateElementRequest>(defaultCreateData)

  function handleChange(data: any) {
    return setItem((prevItem) => ({ ...prevItem, ...data }))
  }

  const [creating, setCreating] = useState(false)
  const [creationError, setCreationError] = useState<Error | null>(null)

  async function handleCreate() {
    try {
      setCreationError(null)
      setCreating(true)
      return await onCreate(item)
    } catch (e) {
      setCreationError(e)
    } finally {
      setCreating(false)
    }
  }

  const handleNameChange = useSyncKeyWithName(handleChange)

  return (
    <Popup.Root defaultOpen onExitComplete={onClose}>
      <Popup.Window width='lg'>
        <Popup.Header>
          <Popup.Title>Create {elementName}</Popup.Title>
        </Popup.Header>
        <Popup.Content>
          <form id={formId} onSubmit={useEventStopPropagation(handleCreate)}>
            <VStack gap={2} alignItems={'stretch'}>
              <SimpleInput
                label={'Name'}
                value={item.name}
                onChange={handleNameChange}
                autoFocus
              />

              <SimpleInput
                label={'Key'}
                value={item.key}
                onChange={(key) => handleChange({ key })}
              />

              {AdditionalInputs && <>{AdditionalInputs}</>}

              {item.integrationId && (
                <Row>
                  <Cell.Name>Integration</Cell.Name>
                  <Cell.Value grow>
                    <IntegrationComboboxSelect
                      variant={'ghost'}
                      width={'full'}
                      marginBlock={-1}
                      useId
                      value={item.integrationId}
                      onChange={(integrationId?: string) =>
                        handleChange({ key: '', integrationId })
                      }
                      disabled
                    />
                  </Cell.Value>
                </Row>
              )}
              {creationError && (
                <Alert.Root variant={'error'} icon={<TbAlertTriangle />}>
                  <Alert.Title>{creationError.message}</Alert.Title>
                </Alert.Root>
              )}
            </VStack>
          </form>
        </Popup.Content>
        <Popup.Footer>
          <Button
            size='dense'
            colorPalette='primary'
            disabled={!isCreateDataValid(item) || creating}
            loading={creating}
            type='submit'
            form={formId}
          >
            Create {elementName}
          </Button>
        </Popup.Footer>
      </Popup.Window>
    </Popup.Root>
  )
}
