import { PropsWithChildren } from 'react'

import { ConfigCard } from 'components/Card/ConfigCard'
import YAMLEditor from 'components/Editors/YamlEditor'
import { CodeModeSwitch, useCodeMode } from 'components/Switch/CodeModeSwitch'
import { css, cva, cx } from 'styled-system/css'
import { Box, BoxProps } from 'styled-system/jsx'

const codeModeClassnames = cva({
  base: {
    position: 'relative',
  },
  variants: {
    codeMode: {
      true: {
        display: 'grid',
        placeContent: 'stretch',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        height: 'calc(100% - 1rem)',
      },
    },
  },
})

export function ConfigCardWrapperWithCodeModeSwitch({
  children,
  onCodeChange,
  code,
  className,
  ...props
}: BoxProps & {
  code: any
  onCodeChange(data: string): void
}) {
  const { codeMode, toggleCodeMode } = useCodeMode()

  // magic fix to avoid constant re-rendering of the editor
  // when scrollbar is always visible (like on windows)

  return (
    <ConfigCard.Wrapper
      paddingBlockStart='9'
      className={cx(codeModeClassnames({ codeMode }), className)}
      {...props}
    >
      <CodeModeSwitch
        codeMode={codeMode}
        onToggle={toggleCodeMode}
        className={css({
          position: 'absolute',
          right: 4,
          top: 4,
        })}
      />
      <CodeModeContent
        codeMode={codeMode}
        code={code}
        onCodeChange={onCodeChange}
      >
        {children}
      </CodeModeContent>
    </ConfigCard.Wrapper>
  )
}

export function ConfigCardWrapperWithCodeMode({
  children,
  onCodeChange,
  code,
  codeMode,
  className,
  ...props
}: BoxProps & {
  code: any
  onCodeChange(data: string): void
  codeMode: boolean
}) {
  // magic fix to avoid constant re-rendering of the editor
  // when scrollbar is always visible (like on windows)

  return (
    <ConfigCard.Wrapper
      className={cx(codeModeClassnames({ codeMode }), className)}
      {...props}
    >
      <CodeModeContent
        codeMode={codeMode}
        code={code}
        onCodeChange={onCodeChange}
      >
        {children}
      </CodeModeContent>
    </ConfigCard.Wrapper>
  )
}

function CodeModeContent({
  children,
  onCodeChange,
  code,
  codeMode,
}: PropsWithChildren<{
  code: any
  onCodeChange(data: string): void
  codeMode: boolean
}>) {
  // magic fix to avoid constant re-rendering of the editor
  // when scrollbar is always visible (like on windows)
  if (codeMode) {
    return (
      <Box
        minHeight='full'
        maxWidth='full'
        borderRadius='l3'
        borderWidth='1'
        borderColor='border.subtle'
        padding='2'
        backgroundColor='bg.default'
        gridArea='1 / 1'
        marginBlockStart='4'
      >
        <YAMLEditor value={code} onChange={onCodeChange} />
      </Box>
    )
  }

  return children
}
