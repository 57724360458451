import { ReactNode } from 'react'
import {
  CreateDataSourceRequest,
  UNIFIED_DATA_MODELS,
} from '@integration-app/sdk'
import { capitalize } from '@integration-app/ui/helpers/capitalize'

import { Popup } from 'components/Popup'
import { ItemButton } from 'components/Popup/CreateSmthPopup/ItemButton'
import { SearchInput, useSearch } from 'components/SearchInput'
import { Text } from 'ui-kit/text'
import { getSlugKey } from 'utils/slug'

import { CreateDataSourcePopupTitle } from './CreateDataSourcePopupTitle'

type UdmOptionType = {
  label: ReactNode
  value?: string
}

const UdmOptions: UdmOptionType[] = Object.keys(UNIFIED_DATA_MODELS).map(
  (u) => ({
    label: capitalize(u),
    value: u,
  }),
)

export function StepSelectUdm({
  title,
  onItemSelect,
  integrationId,
  selectedItem,
}: {
  title?: string
  onItemSelect(
    item: Pick<CreateDataSourceRequest, 'name' | 'udm' | 'key'>,
  ): void
  integrationId?: string
  selectedItem?: Pick<CreateDataSourceRequest, 'name' | 'udm' | 'key'>
}) {
  const { search, setSearch, filtered } = useSearch(UdmOptions, [
    'value',
    'label',
  ])

  if (search.length > 0) {
    filtered.unshift({
      value: getSlugKey(search),
      label: search,
    })
  }

  return (
    <>
      <Popup.Header>
        <CreateDataSourcePopupTitle integrationId={integrationId}>
          {title}
        </CreateDataSourcePopupTitle>
        <Popup.Description>
          <Text m={0}>
            Select a data model to initialize this data source with
          </Text>
        </Popup.Description>
        <SearchInput
          variant={'border'}
          onChange={setSearch}
          value={search}
          placeholder={'Type to search UDM'}
        />
      </Popup.Header>
      <Popup.Content display={'flex'} flexDirection={'column'} gap={2}>
        {filtered.map(({ label, value }: { label: string; value?: string }) => (
          <ItemButton.Root
            onClick={() => {
              if (!value) return
              if (!Object.keys(UNIFIED_DATA_MODELS).includes(value)) {
                onItemSelect({ name: label, key: label, udm: undefined })
              } else {
                onItemSelect({ name: label, key: value, udm: value })
              }
            }}
            variant={selectedItem?.udm === value ? 'outline' : 'subtle'}
          >
            <ItemButton.Header>{label}</ItemButton.Header>
          </ItemButton.Root>
        ))}
      </Popup.Content>
    </>
  )
}
