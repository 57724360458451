import { Cell, ComboBox, Row } from '@integration-app/react'
import { OperationSpec } from '@integration-app/sdk'
import useSWR from 'swr'

import { ConfigCard } from 'components/Card/ConfigCard'
import { useWorkspace } from 'components/Workspaces/workspace-context'
import { useGenericConfig } from 'routes/Workspaces/Workspace/components/common-configs/contexts/generic-config-context'

import { IntegrationSpecificOperationConfig } from '../../../../../../../components/common-configs/IntegrationSpecificOperationConfig'

export function IntegrationSpecificOperation() {
  const { engineAdminFetcher } = useWorkspace()
  const { config, patchConfig, integrationId } = useGenericConfig()

  const { data: operations } = useSWR<Partial<OperationSpec>[]>(
    integrationId ? `/integrations/${integrationId}/operations` : null,
    engineAdminFetcher,
  )

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>Select Operation</ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content>
          <Row>
            <Cell.Name>Operation</Cell.Name>
            <Cell.Combobox>
              <ComboBox
                value={config.operationKey}
                onChange={(operationKey) => patchConfig({ operationKey })}
                options={
                  operations?.map((operation) => ({
                    label: operation.name ?? '',
                    value: operation.key ?? '',
                  })) ?? []
                }
                placeholder='Select operation...'
              />
            </Cell.Combobox>
          </Row>
        </ConfigCard.Content>
      </ConfigCard.Root>

      {config.operationKey && (
        <ConfigCard.Root>
          <ConfigCard.Header>
            <ConfigCard.Title>
              Setup Integration Specific Operation
            </ConfigCard.Title>
          </ConfigCard.Header>
          <ConfigCard.Content>
            <IntegrationSpecificOperationConfig />
          </ConfigCard.Content>
        </ConfigCard.Root>
      )}
    </>
  )
}
