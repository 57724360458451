import {
  useAppEventTypes,
  ComboBox,
  ValueType,
  Cell,
  Row,
} from '@integration-app/react'

import { GoToElementLink } from 'components/GoToElemenLink'
import { routeTo } from 'routes/Workspaces/Workspace/routes-constants'

export default function AppEventTypeSelect({
  value,
  onChange,
}: {
  value: string | undefined
  onChange: (value: string | undefined) => void
}) {
  const { appEventTypes } = useAppEventTypes()

  const options = appEventTypes?.map(({ key, name }) => ({
    value: key,
    label: name,
  }))

  const selected = appEventTypes.find(({ key }) => key === value)

  return (
    <div className='flex items-center gap-4'>
      <Row>
        <Cell.Name shrink>App Event Type</Cell.Name>
        <Cell.Combobox grow>
          <ComboBox
            placeholder='Select App Event Type'
            value={value}
            options={options}
            onChange={onChange}
            valueSpec={{ type: ValueType.STRING, allowCustom: false }}
          />
        </Cell.Combobox>
      </Row>

      {selected && <GoToElementLink to={routeTo.appEventType(selected.id)} />}
    </div>
  )
}
