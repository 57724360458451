import { PropsWithChildren } from 'react'
import { DataSource } from '@integration-app/sdk'

import { Popup } from 'components/Popup'

import {
  CreateIntegrationSpecificDataSource,
  CreateUniversalDataSource,
  StepCreateFlow,
} from './StepCreateFlow'

export enum DataSourceType {
  Universal = 'universal',
  IntegrationSpecific = 'integrationSpecific',
}

export function CreateDataSourcePopup({
  integrationId,
  open,
  onClose,
  onCreate,
}: {
  integrationId?: string
  onClose: () => void
  open: boolean
  onCreate: (item: DataSource) => void
}) {
  const dataSourceType = integrationId
    ? DataSourceType.IntegrationSpecific
    : DataSourceType.Universal

  function handleOnCreate(dataSource: DataSource) {
    onClose()
    onCreate(dataSource)
  }

  if (!open) return null

  return (
    <PopupWrapper onClose={onClose}>
      <StepCreateFlow
        dataSourceType={dataSourceType}
        integrationId={integrationId}
        onBack={onClose}
        onCreate={handleOnCreate}
      />
    </PopupWrapper>
  )
}

type PopupCreateDataSourceProps = {
  title?: string
  open: boolean
  onClose: () => void
  onCreate: (item: DataSource) => void
  integrationId: string
  readOnlyIntegration?: boolean
}

export function PopupCreateIntegrationSpecificDataSource({
  title,
  open,
  onClose,
  onCreate,
  integrationId,
  readOnlyIntegration,
}: PopupCreateDataSourceProps) {
  function handleOnCreate(dataSource: DataSource) {
    onCreate(dataSource)
    onClose()
  }

  if (!open) return null

  return (
    <PopupWrapper onClose={onClose}>
      <CreateIntegrationSpecificDataSource
        title={title}
        integrationId={integrationId}
        readOnlyIntegration={readOnlyIntegration}
        onCreate={handleOnCreate}
      />
    </PopupWrapper>
  )
}

export function PopupCreateUniversalDataSource({
  title,
  open,
  onClose,
  onCreate,
}: Omit<PopupCreateDataSourceProps, 'integrationId' | 'readOnlyIntegration'>) {
  function handleOnCreate(dataSource: DataSource) {
    onCreate(dataSource)
    onClose()
  }

  if (!open) return null

  return (
    <PopupWrapper onClose={onClose}>
      <CreateUniversalDataSource title={title} onCreate={handleOnCreate} />
    </PopupWrapper>
  )
}

function PopupWrapper({
  onClose,
  children,
}: PropsWithChildren<{
  onClose: () => void
}>) {
  return (
    <Popup.Root defaultOpen onExitComplete={onClose}>
      <Popup.Window width='lg'>{children}</Popup.Window>
    </Popup.Root>
  )
}
